<template>
  <div class="watch-page" ref="refPage">
    <Watch
      :online-users="onlineUsers"
      :width="width"
      @on-is-online-stream="onIsOnlineSteam"
      @on-is-anon="onIsAnon"
      @on-owner="onOwner"
    />
    <ChatOnline
      v-if="isChatOnline"
      :is-anon-editor="isAnonEditor"
      :owner="owner"
      @on-online-users="onOnlineUsers"
    />
    <ChatOffline v-if="isChatOffline" :owner="owner" />
  </div>
</template>

<script>
import Watch from './comp/watch'
import ChatOnline from '@/comp/chat/chat-online'
import ChatOffline from '@/comp/chat/chat-offline'
import { STREAM_STATE } from './fumc'

export default {
  name: 'watch-page',
  components: { Watch, ChatOnline, ChatOffline },
  data() {
    return {
      onlineUsers: 0,
      stateStream: STREAM_STATE.UNDEFINED,
      isChatOnline: false,
      isChatOffline: false,
      isAnonEditor: false,
      width: 0,
      owner: ''
    }
  },
  methods: {
    onOnlineUsers(user) {
      this.onlineUsers = user
    },
    onIsOnlineSteam(isOnline) {
      if (isOnline) this.stateStream = STREAM_STATE.ONLINE
      else this.stateStream = STREAM_STATE.OFFLINE
    },
    onIsAnon(item) {
      this.isAnonEditor = item
    },
    resizeBrowserHandler() {
      this.width = this.$refs.refPage.clientWidth
    },
    onOwner(owner) {
      this.owner = owner
    }
  },
  watch: {
    stateStream() {
      if (this.stateStream === STREAM_STATE.ONLINE) this.isChatOnline = true
      if (this.stateStream === STREAM_STATE.OFFLINE) this.isChatOffline = true
    }
  },
  created() {
    window.addEventListener('resize', this.resizeBrowserHandler)
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeBrowserHandler)
  },
  mounted() {
    this.resizeBrowserHandler()
  }

}
</script>

<style lang="scss">
@import './styles';
</style>
