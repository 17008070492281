<template>
  <div class="watch-footer-roll-up">
    <div class="roll-up-users" v-if="onlineUsers">
      <Person />
      <span>{{ onlineUsers }}</span>
    </div>
    <mts-button
      class="btn-hide-player"
      theme="dark"
      type="outline"
      size="s"
      @click="() => this.$emit('on-show')"
    >{{ btnName}}
    </mts-button>
  </div>
</template>

<script>
import Person from '@/pic/svg/person'

export default {
  name: 'watch-footer-roll-up',
  components: { Person },
  props: {
    onlineUsers: { type: Number },
    isPlayer: { type: Boolean }
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    btnName() {
      if (this.isPlayer) return 'скрыть плеер'
      else return 'показать плеер'
    }
  }
}
</script>

<style lang="scss">
@import 'styles/index';
</style>
