<template>
  <div class="watch">
    <Player v-if="isPlayer" :stream-key="streamKey" :style="{ height: hPlayer }" />
    <div v-if="isPlayerEmpty" class="player-empty" :style="{ height: hPlayer }">
      <span v-if="isShow">Трансляция завершена</span>
    </div>
    <Footer
      :name="name"
      :desc="desc"
      :date="date"
      :attachFile="attachFile"
      :online-users="onlineUsers"
    />
    <FooterRollUp :online-users="onlineUsers" @on-show="onShow" :is-player="isShow" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GETTER_APP } from '@/store/const/type'
import Player from '../../../../comp/player'
import Footer from './comp/footer'
import FooterRollUp from './comp/footer-roll-up'
import { getAxiosAttachFile } from '@/comp/utils'
import { getAxiosBroadcast, postAxiosParticipant } from './func'

export default {
  name: 'watch',
  components: { Footer, Player, FooterRollUp },
  props: {
    onlineUsers: { type: Number, default: 0 },
    width: { type: Number, default: 0 }
  },
  data() {
    return {
      id: this.$route.params.id,
      name: '',
      desc: '',
      date: new Date().toISOString(),
      attachFile: null,
      life: '',
      streamKey: '',
      isPlayer: false,
      isPlayerEmpty: false,
      isAnon: false,
      isShow: true,
      owner: '1'
    }
  },
  computed: {
    ...mapGetters({ getterAppUser: GETTER_APP.USER }),
    hPlayer() {
      if (this.width > 900) return '100%'
      if (this.isShow) return '200px'
      else return '0'
    }
  },
  methods: {
    setBroadcast(name, desc, dateTime, life, stream_key, is_anon, owner) {
      this.name = name
      this.desc = desc
      this.date = dateTime
      this.life = life
      this.streamKey = stream_key
      this.isAnon = is_anon
      this.owner = owner
    },
    setAttachFileLoaded(attachFile) {
      this.attachFile = attachFile
    },
    onShow() {
      this.isShow = !this.isShow
    }
  },
  watch: {
    life() {
      let isOnline = false
      if (this.life === 'created') {
        isOnline = true
        this.isPlayer = true
        postAxiosParticipant(this.id, this.getterAppUser)
      } else {
        this.isPlayerEmpty = true
      }
      this.$emit('on-is-online-stream', isOnline)
    },
    isAnon() {
      this.$emit('on-is-anon', this.isAnon)
    },
    owner() {
      this.$emit('on-owner', this.owner)
    }
  },
  mounted() {
    getAxiosBroadcast(this.id, this.setBroadcast)
    getAxiosAttachFile(this.id, this.setAttachFileLoaded)
  }
}
</script>
<style lang="scss">
@import './styles';
</style>
