<template>
  <ChatOffline :chat-list="chatList" :username="username" :is-admin="isAdminOwn" :is-past="false" />
</template>

<script>
import { mapGetters } from 'vuex'
import { GETTER_APP } from '@/store/const/type'
import ChatOffline from './chat-offline'
import { getAxiosMsgBroadcastById } from './func'
export default {
  name: 'index-chat-offline',
  components: { ChatOffline },
  props: {
    owner: { type: String }
  },
  data() {
    return {
      chatList: {},
      channel: this.$route.params.id
    }
  },
  methods: {
    setStateChatList(chat) {
      this.chatList = chat
    }
  },
  computed: {
    ...mapGetters({ getGettersUser: GETTER_APP.USER, isAdmin: GETTER_APP.IS_ADMIN }),
    username() {
      return this.getGettersUser.username
    },
    isAdminOwn() {
      if (this.isAdmin) return true
      return this.owner === this.username
    }
  },
  created() {
    getAxiosMsgBroadcastById(this.channel, this.setStateChatList)
  }
}
</script>
